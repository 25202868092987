function HoverListener(coordinateGrid) {
	var t = this;
	var bindType;
	var change;
	var firstCell;
	var cell;
	var scrollContainer;
	var calendarContainer;
	var originalScrollPosition;
	var containerPosition;
	var dragTarget;
	var disabled;

	t.start = function (_change, ev, _bindType) {
		//Todo: is there a better way we could reference the containers that is less brittle?
		scrollContainer = $('.calendar-scroll');
		calendarContainer = $('.fc');
		dragTarget = $(ev.target);
		containerPosition = calendarContainer.offset();
		originalScrollPosition = scrollContainer.scrollTop();
		change = _change;
		firstCell = cell = null;
		coordinateGrid.build();
		mouse(ev);
		bindType = _bindType || 'mousemove';
		$(document).bind(bindType, mouse);
	};

	function mouse(ev) {
		disabled = dragTarget.hasClass('cancel-drop');
		//We don't want to select if mouse is above top of container
		if (ev.pageY < containerPosition.top || disabled) {
			//Clear out cell data and apply change so we don't stay highlighted
			cell = null;
			change(null, null);
			return;
		}

		var scrollPosition = scrollContainer.scrollTop();
		// _fixUIEvent(ev); // see below
		var newCell = coordinateGrid.cell(ev.pageX, ev.pageY);
		if (
			Boolean(newCell) !== Boolean(cell) ||
			(newCell && (newCell.row != cell.row || newCell.col != cell.col)) ||
			scrollPosition !== originalScrollPosition
		) {
			if (newCell) {
				if (!firstCell) {
					firstCell = newCell;
				}
				change(
					newCell,
					firstCell,
					newCell.row - firstCell.row,
					newCell.col - firstCell.col
				);
			} else {
				change(newCell, firstCell);
			}
			cell = newCell;
		}
	}

	t.stop = function () {
		$(document).unbind(bindType, mouse);
		return cell;
	};
}
